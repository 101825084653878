import React, { lazy, useEffect, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import "react-toastify/dist/ReactToastify.css";
import {
  actionS3Objects,
  ListingType,
  DealStatus,
  DealCategory,
  DealClientType,
  newRoles,
  formatValue,
} from "../../../utils/envConfig";
import { toast } from "react-toastify";
import {
  UncontrolledDropdown,
  DropdownMenu,
  DropdownToggle,
  DropdownItem,
  Modal,
  ModalHeader,
  ModalBody,
  Input,
  ModalFooter,
  Spinner,
} from "reactstrap";
import { Button, Icon, TooltipComponent } from "../../../components/Component";
import { useDispatch } from "react-redux";
import useAxiosPrivate from "../../../hooks/useAxiosPrivate";
import queryString from "query-string";
import { fetchDeals } from "../../../redux/actions/deal";
import { useSelector } from "react-redux";
import { format } from "date-fns";
import { useConvertDealModal } from "./convert-to-listing/modal/ConvertDealContext";

const ConvertDealToListingModal = lazy(() => import("./convert-to-listing/modal/ConvertDealToListingModal"));

const DealCard = ({
  deal,
  user,
  agent,
  property,
  newListing,
  agentsFiltered,
}) => {
  
  const dispatch = useDispatch();
  const authuser = useSelector((state) => state?.user?.loggedInUser);

  const location = useLocation();

  const axios = useAxiosPrivate();

  const { isOpen: isConvertDealModalOpen, toggle: toggleConvertDealModal } = useConvertDealModal();

  const query = queryString.parse(location.search);

  const [loading, setLoading] = useState(false);

  const [agentSelected, setAgentSelected] = useState(agentsFiltered);
  const [imgUrl, setImgUrl] = useState(null);
  const [imgLoading, setImgLoading] = useState(true);
  const [price, setPrice] = useState(0);
  const [monthPrice, setMonthPrice] = useState(0);
  const [imgAgentUrl, setAgentImgUrl] = useState(null);
  const [imgAgentLoading, setAgentImgLoading] = useState(true);
  const [imgOwnerUrl, setOwnerImgUrl] = useState(null);
  const [imgOwnerLoading, setOwnerImgLoading] = useState(true);
  const [docsAvailable, setDocsAvailable] = useState(false);

  const [approveDeal, setApproveDeal] = useState(false);
  const [openApproveModal, setOpenApproveModal] = useState(false);
  const [openCancelModal, setOpenCancelModal] = useState(false);
  const [note, setNote] = useState("");
  const [notes, setNotes] = useState(null);
  const [rejectionArray, setRejectionArray] = useState(deal?.rejection || []);

  const [modalText, setModalText] = useState();

  useEffect(() => {
    setAgentSelected(agentsFiltered);

    async function fetchDocuments() {
      try {
        const res = await axios
          .get(`/documents/deal/${deal.id}`)
          .then((response) => {
            if (response.data?.length > 0) {
              setDocsAvailable(true);
            }
          });
      } catch (err) {
        // console.log(err)
      }
    }
    if (newListing) {
      fetchDocuments();
    }
  }, []);
  useEffect(() => {
    async function fetchNotes() {
      try {
        const { data: notesData } = await axios.get(`/notes/deal/${deal.id}`);
        const myNotes = deal?.rejection
          ? deal.rejection.map((dl) => {
            const note = notesData.find((note) => note.id === dl.note);
            return {
              date: dl.date,
              description: note
                ? note.description
                : "No description available",
            };
          })
          : [];
        const lastMyNote =
          myNotes?.length > 0 ? myNotes[myNotes?.length - 1] : null;
        if (lastMyNote) {
          // Save the last note to the 'notes' state (or wherever you want to save it)
          setNotes([lastMyNote]);
        }
      } catch (err) {
        // console.log(err)
      }
    }
    fetchNotes();
  }, [deal]);

  useEffect(() => {
    if (property?.images && property?.images?.length > 0) {
      setImgLoading(true);
      let promise = getUrl(property.images[0].thumbnail);
      promise.then((url) => setImgUrl(url)).finally(() => setImgLoading(false));
    } else {
      setImgLoading(false);
    }
    if (agent?.id) {
      if (agent?.user?.photo) {
        setAgentImgLoading(true);
        let promise = getUrl(agent?.user?.photo);
        promise
          .then((url) => setAgentImgUrl(url))
          .finally(() => setAgentImgLoading(false));
      } else {
        setAgentImgUrl("./img/profile.png");
        setAgentImgLoading(false);
      }
    } else {
      setAgentImgLoading(false);
    }
    if (user?.id) {
      if (user?.photo) {
        setOwnerImgLoading(true);
        let promise = getUrl(user?.photo);
        promise
          .then((url) => setOwnerImgUrl(url))
          .finally(() => setOwnerImgLoading(false));
      } else {
        setOwnerImgUrl("./img/profile.png");
        setOwnerImgLoading(false);
      }
    } else {
      setAgentImgUrl("./img/profile.png");
      setOwnerImgLoading(false);
    }

    if (
      property?.listingType === ListingType[0] ||
      property?.listingType === ListingType[2]
    ) {
      setPrice(property.askingPrice);
      setMonthPrice(property.askingPrice / 12);
    } else {
      setPrice(property?.oneCheqPrice);
      setMonthPrice(property?.emi);
    }
  }, [property]);

  const queryDeals = () => {
    // the query below will consider the params first. If the value is not available in params, then it tries to get from the query. If not available in query, then it returns undefined.
    // If you had already pass an argument, this argument will be available in the query, thus, if u want to remove this item from the query, you need to pass a string of 'null' in the params object so that the cleanup function will remove it before passing to search query.

    const dealParams = {
      ...query,
      per_page: query?.per_page ?? 10,
      page: query?.page ?? 1,
    };

    // clean-up the query object above to search only values that are not falsely and do not contain the string 'null'
    const searchQuery = Object.entries(dealParams)
      ?.filter(([key, value]) => key && value && value !== "null")
      ?.map(([key, value]) => `${key}=${value}`)
      .join("&");

    return searchQuery;

    // console.log(searchQuery);
  };

  const refreshDeals = async () => {
    try {
      const searchParams = queryDeals();

      await fetchDeals(axios, `?${searchParams}`)(dispatch);
    } catch (err) {
      //   console.log(err);
    }
  };

  const getUrl = async (file) => {
    try {
      const uploadData = JSON.stringify({
        action: `${actionS3Objects[0]}`,
        fileName: file,
      });
      const resp = await axios.post(`/upload`, uploadData);
      const s3url = resp.data.signedRequest;
      if (s3url) {
        return s3url;
      }
    } catch (err) {
      console.log(err);
    }
  };

  const handleCancel = (approved) => async () => {
    try {
      setLoading(true);

      if (!approveDeal && (!note?.length || note.split(" ")?.length < 3)) {
        toast.error(
          note.split(" ").length < 3
            ? "Note too short. Minimum 3 words"
            : "Please add a reason for rejecting deal"
        );

        return;
      }

      if (approveDeal && note?.length > 0 && note.split(" ")?.length < 3) {
        toast.error("Note should be at least 3 words.");
        return;
      }

      const noteParams = {
        description: note,
        deal_id: deal?.id,
      };

      note &&
        note?.length &&
        note.split(" ")?.length >= 10 &&
        (await axios.post("/notes", noteParams));

      const { data } = await axios.post(`/deals/progress/${deal?.id}`, {
        cancelled: true,
      });

      setOpenCancelModal(false);
      await refreshDeals();
    } catch (err) {
      //   console.log(err);
      toast.error(err?.response?.data?.message ?? "Unexpected server error.");
    } finally {
      setLoading(false);
    }
  };

  const handleApprove = (approved, convertedDeal = null) => async () => {
    try {
      setLoading(true);

      if (!approveDeal && (!note?.length || note.split(" ")?.length < 3)) {
        toast.error(
          note.split(" ").length < 3
            ? "Note too short. Minimum 3 words"
            : "Please add a reason for rejecting deal"
        );

        return;
      }

      if (approveDeal && note?.length > 0 && note.split(" ")?.length < 3) {
        toast.error("Note should be at least 3 words.");
        return;
      }

      const noteParams = {
        description: note,
        deal_id: deal?.id,
      };

      if (convertedDeal) {
        setOpenApproveModal(false);
        toggleConvertDealModal();
      } else {
        note &&
          note?.length &&
          note.split(" ")?.length >= 10 &&
          (await axios.post("/notes", noteParams));

        const { data } = await axios.post(`/deals/progress/${deal?.id}`, {
          approved,
        });
        setOpenApproveModal(false);
        await refreshDeals();
      }
    } catch (err) {
      //   console.log(err);
      toast.error(err?.response?.data?.message ?? "Unexpected server error.");
    } finally {
      setLoading(false);
    }
  };
  const handleSendBack = () => async () => {
    try {
      setLoading(true);

      if (!approveDeal && (!note?.length || note.split(" ")?.length < 3)) {
        toast.error(
          note.split(" ").length < 3
            ? "Note too short. Minimum 3 words"
            : "Please add a reason for rejecting deal"
        );

        return;
      }

      const noteParams = {
        description: note,
        deal_id: deal?.id,
      };

      if (note && note?.length && note.split(" ")?.length >= 3) {
        try {
          const response = await axios.post("/notes", noteParams);
          const note_id = response.data?.id;

          const newRejection = {
            date: new Date(),
            note: note_id,
          };

          const updatedRejectionArray = [...rejectionArray, newRejection];
          setRejectionArray(updatedRejectionArray);

          await axios.post(`/deals/progress/${deal?.id}`, {
            rejection: updatedRejectionArray,
          });

          //   console.log("Progress update successful.");
        } catch (error) {
          //   console.error("Error while processing note and progress:", error);
        }
      }

      setOpenApproveModal(false);
      await refreshDeals();
    } catch (err) {
      //   console.log(err);
      toast.error(err?.response?.data?.message ?? "Unexpected server error.");
    } finally {
      setLoading(false);
    }
  };

  const calculateDealAmount = (deal) => {
    if (deal?.agreedSalePrice) {
      return formatValue(deal?.agreedSalePrice);
    }

    switch (deal?.category) {
      case DealCategory.SecondaryProperty:
        const isInternal = deal?.sellerClientType === DealClientType.Alba;

        const property = deal?.property_list;
        const externalProperty = deal?.external_listing_property;

        return isInternal
          ? formatValue(
            property?.askingPrice ??
            property?.oneCheqPrice ??
            property?.twoCheqPrice ??
            property?.fourCheqPrice ??
            property?.sixCheqPrice ??
            property?.twelveCheqprice ??
            0
          )
          : formatValue(externalProperty?.agreedSalesPrice ?? 0);
      case DealCategory.PrimaryOffplan:
        return formatValue(deal?.new_listing_offplan?.agreedSalesPrice ?? 0);
      case DealCategory.ListingAcquired:
        return formatValue(deal?.new_listing_request?.agreedSalesPrice ?? 0);

      default:
        return 0;
    }
  };
  const [showDocsMissingModal, setShowDocsMissingModal] = useState(false);

  const checkIfLeadsBelongsToAuthUser = (deal) => {
    let result = false;
    if(deal.category == DealCategory.SecondaryProperty) {

      return deal?.buyerAssignedAgent?.userId === authuser?.id || deal?.sellerAssignedAgent?.userId === authuser?.id;
    }

     if(deal.category == DealCategory.PrimaryOffplan) {
      return deal?.assignedAgent === authuser?.id;
    }

   return deal?.assignedAgent?.userId === authuser?.id;
      

  }

  console.log("Is TL", authuser.role == newRoles.TeamLeader);  return (
    <>
      <tr className="tb-odr-item" key={deal.id}>
        <td className="tb-odr-info bg-white">
          <span className="tb-odr-id">
            {
              [newRoles.Admin, newRoles.MasterAdmin].includes(authuser.role) ? (
                <Link to={`/deal-details/${deal.id ?? deal._id}`}>
                  {deal?.reference_number}
                </Link>
              ) : authuser.role == newRoles.TeamLeader ?
                (authuser?.id === deal?.assignedAgent?.userId) ? (
                  <Link to={`/deal-details/${deal.id ?? deal._id}`}>
                    {deal?.reference_number}
                  </Link>
                ) :   checkIfLeadsBelongsToAuthUser(deal) ? <Link to={`/deal-details/${deal.id ?? deal._id}`}>
                    {deal?.reference_number}
                  </Link>: (<span >{deal?.reference_number}</span>) :    <Link to={`/deal-details/${deal.id ?? deal._id}`}>
                    {deal?.reference_number}
                  </Link>
            }

          </span>
        </td>
        <td className="bg-white">
          <span className="tb-odr-id">
            {deal?.creation_date &&
              format(new Date(deal?.creation_date), "dd/MM/yyyy")}
          </span>
        </td>
        <td className="bg-white">
          <span className="tb-odr-badge">{deal?.category}</span>
        </td>
        <td className="bg-white">
          <div className="tb-odr-date ">
            {deal?.category === DealCategory.SecondaryProperty ? (
              <RenderSecondaryAgent deal={deal} />
            ) : (
              <>
                {deal?.assignedAgent
                  ? `${deal?.assignedAgent?.user?.first_name} ${deal?.assignedAgent?.user?.last_name}`
                  : "No Agent"}
              </>
            )}
          </div>
        </td>
        <td className="bg-white text-center">
          <span className="tb-odr-badge">
            {newListing?.property_type?.propertyType ??
              deal?.new_listing_offPlan?.property_type?.propertyType ??
              "_"}
          </span>
        </td>
        <td className="bg-white text-center">
          <span className="tb-odr-badge">
            {deal?.purpose ?? newListing?.type ?? property?.listingType ?? "_"}
          </span>
        </td>
        <td className="bg-white text-center px-4">
          <span className="tb-odr-badge">
            {calculateDealAmount(deal) ?? "_"}
          </span>
        </td>
        <td className="bg-white text-center px-4">
          <span className="tb-odr-badge">
            {deal?.new_listing_request ? (deal.new_listing_request.exclusive ? 'Yes' : 'No') : 'N/A'}
          </span>
        </td>
        <td className="bg-white tb-odr-i ">
          <span>
            {newListing?.community?.community ?? property?.communityName ?? "_"}
          </span>
        </td>
        <td className="bg-white tb-odr-i">
          <span className="">
            {property?.buildingName ?? newListing?.building?.name ?? "_"}
          </span>
        </td>

        <td className="bg-white">
          <div className="tb-odr-total tb-odr-date">
            {deal?.category === DealCategory.SecondaryProperty ? (
              <RenderSecondaryClient deal={deal} />
            ) : (
              <>{`${deal?.user?.first_name} ${deal?.user?.last_name}`}</>
            )}
          </div>
        </td>
        <td className="bg-white">
          {" "}
          <span className="tb-odr-status">
            {deal?.rejection?.length !== 0 &&
              deal?.status === DealStatus.CollectingDocuments &&
              notes?.length > 0 && (
                <TooltipComponent
                  tag="a"
                  containerClassName="btn btn-icon"
                  id={"view" + deal.id}
                  icon="alert-fill"
                  direction="top"
                  text={notes[0]?.description}
                />
              )}
            {deal?.status}
          </span>
        </td>
        <td className="tb-odr-action bg-white">
          <div className="tb-odr-btns d-flex justify-content-end">
            {authuser?.role && !([newRoles.Agent, newRoles.AnonymousAgent, newRoles.TeamLeader].includes(authuser?.role)) && (
              <>
                {!(
                  deal?.status !== DealStatus.PendingApproval ||
                  (newListing && !docsAvailable)
                ) && (
                    <Button
                      onClick={() => {
                        setApproveDeal(true);
                        setOpenApproveModal(true);
                        setModalText(
                          <>
                            You are going to approve deal N<sup>o</sup>{" "}
                            {deal?.reference_number}
                          </>
                        );
                      }}
                      disabled={
                        deal?.status !== DealStatus.PendingApproval ||
                        (newListing && !docsAvailable)
                      }
                      color="success"
                      size="sm"
                      className="btn btn-dim me-1"
                    >
                      Approve
                    </Button>)}
                {(
                  deal?.status === DealStatus.PendingApproval &&
                  (newListing && !docsAvailable)
                ) && (
                    <div className="d-flex align-items-center">
                      {!docsAvailable && (
                        <span
                          type="button"
                          className="ms-2"
                          style={{ color: 'indigo' }}
                          onClick={() => setShowDocsMissingModal(true)}
                        >
                          <em className="icon ni ni-help-fill"></em>
                        </span>
                      )}
                      <Button
                        onClick={() => {
                          if (newListing && !docsAvailable) { setShowDocsMissingModal(true); return; };
                          if (deal?.status === DealStatus.PendingApproval && (!newListing || docsAvailable)) {
                            setApproveDeal(true);
                            setOpenApproveModal(true);
                            setModalText(
                              <>
                                You are going to approve deal N<sup>o</sup> {deal?.reference_number}
                              </>
                            );
                          }
                        }}
                        disabled={
                          deal?.status !== DealStatus.PendingApproval ||
                          (newListing && !docsAvailable)
                        }
                        color={docsAvailable ? "success" : "light"}
                        size="sm"
                        className={`btn btn-dim me-1 ${!docsAvailable ? 'shadow' : ''}`}
                        title={!docsAvailable ? "Documents are missing" : ""}
                      >
                        Approve
                      </Button>
                    </div>
                  )}
                {deal?.status === DealStatus.PendingApproval && (
                  <Button
                    onClick={() => {
                      setApproveDeal(false);
                      setOpenApproveModal(true);
                      setModalText(
                        <>
                          You are going to reject deal N<sup>o</sup>{" "}
                          {deal?.reference_number}
                        </>
                      );
                    }}
                    disabled={deal?.status !== DealStatus.PendingApproval}
                    color="danger"
                    size="sm"
                    className="btn btn-dim me-1"
                  >
                    Reject
                  </Button>
                )}
              </>
            )}
        
            {
                [newRoles.Admin, newRoles.MasterAdmin].includes(authuser.role) ? (
                    <UncontrolledDropdown>
              <DropdownToggle
                tag="a"
                href="toggle"
                onClick={(ev) => ev.preventDefault()}
                className="dropdown-toggle btn btn-sm btn-icon btn-trigger "
              >
                <Icon name="more-v"></Icon>
              </DropdownToggle>
              <DropdownMenu end>
                <ul className="link-list-opt no-bdr">
                  <li>
                    <DropdownItem tag="a" href={`/deal-details/${deal?.id}`}>
                      <Icon name="eye"></Icon>
                      <span>View Deal</span>
                    </DropdownItem>
                  </li>
                  {deal?.property_list?.id && (
                    <li>
                      <Link to={`/deal-details/${deal.id ?? deal._id}`}>
                        <Icon name="file"></Icon>
                        <span>Property Info</span>
                      </Link>
                    </li>
                  )}
                  {deal?.status === DealStatus?.CollectingDocuments && (
                    <li>
                      <DropdownItem tag="a" href={`/deal-details/${deal.id ?? deal._id}`}>
                        <Icon name="edit"></Icon>
                        <span>Edit Deal </span>
                      </DropdownItem>
                    </li>
                  )}
                  {deal?.status !== DealStatus.Canceled && (
                    <li>
                      <DropdownItem
                        tag="a"
                        onClick={() => {
                          setApproveDeal(false);
                          setOpenCancelModal(true);
                          setModalText(
                            <>
                              You are going to cancel deal N<sup>o</sup>{" "}
                              {deal?.reference_number}
                            </>
                          );
                        }}
                      >
                        <Icon name="eye"></Icon>
                        <span>Cancel Deal</span>
                      </DropdownItem>
                    </li>
                  )}
                </ul>
              </DropdownMenu>
            </UncontrolledDropdown>
                ) : authuser.role == newRoles.TeamLeader ?
                (authuser?.id === deal?.assignedAgent?.userId) ? (
                      <UncontrolledDropdown>
              <DropdownToggle
                tag="a"
                href="toggle"
                onClick={(ev) => ev.preventDefault()}
                className="dropdown-toggle btn btn-sm btn-icon btn-trigger "
              >
                <Icon name="more-v"></Icon>
              </DropdownToggle>
              <DropdownMenu end>
                <ul className="link-list-opt no-bdr">
                  <li>
                    <DropdownItem tag="a" href={`/deal-details/${deal?.id}`}>
                      <Icon name="eye"></Icon>
                      <span>View Deal</span>
                    </DropdownItem>
                  </li>
                  {deal?.property_list?.id && (
                    <li>
                      <Link to={`/deal-details/${deal.id ?? deal._id}`}>
                        <Icon name="file"></Icon>
                        <span>Property Info</span>
                      </Link>
                    </li>
                  )}
                  {deal?.status === DealStatus?.CollectingDocuments && (
                    <li>
                      <DropdownItem tag="a" href={`/deal-details/${deal.id ?? deal._id}`}>
                        <Icon name="edit"></Icon>
                        <span>Edit Deal </span>
                      </DropdownItem>
                    </li>
                  )}
                  {deal?.status !== DealStatus.Canceled && (
                    <li>
                      <DropdownItem
                        tag="a"
                        onClick={() => {
                          setApproveDeal(false);
                          setOpenCancelModal(true);
                          setModalText(
                            <>
                              You are going to cancel deal N<sup>o</sup>{" "}
                              {deal?.reference_number}
                            </>
                          );
                        }}
                      >
                        <Icon name="eye"></Icon>
                        <span>Cancel Deal</span>
                      </DropdownItem>
                    </li>
                  )}
                </ul>
              </DropdownMenu>
            </UncontrolledDropdown>
                ) : <></> : <></>
            }
            {/* <UncontrolledDropdown>
              <DropdownToggle
                tag="a"
                href="toggle"
                onClick={(ev) => ev.preventDefault()}
                className="dropdown-toggle btn btn-sm btn-icon btn-trigger "
              >
                <Icon name="more-v"></Icon>
              </DropdownToggle>
              <DropdownMenu end>
                <ul className="link-list-opt no-bdr">
                  <li>
                    <DropdownItem tag="a" href={`/deal-details/${deal?.id}`}>
                      <Icon name="eye"></Icon>
                      <span>View Deal</span>
                    </DropdownItem>
                  </li>
                  {deal?.property_list?.id && (
                    <li>
                      <Link to={`/deal-details/${deal.id ?? deal._id}`}>
                        <Icon name="file"></Icon>
                        <span>Property Info</span>
                      </Link>
                    </li>
                  )}
                  {deal?.status === DealStatus?.CollectingDocuments && (
                    <li>
                      <DropdownItem tag="a" href={`/deal-details/${deal.id ?? deal._id}`}>
                        <Icon name="edit"></Icon>
                        <span>Edit Deal </span>
                      </DropdownItem>
                    </li>
                  )}
                  {deal?.status !== DealStatus.Canceled && (
                    <li>
                      <DropdownItem
                        tag="a"
                        onClick={() => {
                          setApproveDeal(false);
                          setOpenCancelModal(true);
                          setModalText(
                            <>
                              You are going to cancel deal N<sup>o</sup>{" "}
                              {deal?.reference_number}
                            </>
                          );
                        }}
                      >
                        <Icon name="eye"></Icon>
                        <span>Cancel Deal</span>
                      </DropdownItem>
                    </li>
                  )}
                </ul>
              </DropdownMenu>
            </UncontrolledDropdown> */}
          </div>
          <Link to={`/deal-details/${deal.id ?? deal._id}`}>
            <Button className="btn-pd-auto d-sm-none">
              <Icon name="chevron-right"></Icon>
            </Button>
          </Link>
        </td>
      </tr>

      <Modal
        isOpen={showDocsMissingModal}
        toggle={(e) => setShowDocsMissingModal(false)}
      >
        <ModalBody>
          <Icon name={"alert-fill"} />Client documents are missing
        </ModalBody>
        <ModalFooter>
          <Button
            onClick={() => setShowDocsMissingModal(false)}
            className="ml-4"
          >
            Close
          </Button>
        </ModalFooter>
      </Modal>

      <Modal
        isOpen={openApproveModal}
        toggle={(e) => setOpenApproveModal(false)}
        size="lg"
      >
        <ModalHeader>
          {!approveDeal && <Icon name={"alert-fill"} />}
          <span>{modalText}</span>
        </ModalHeader>
        <ModalBody>
          <Input
            type="textarea"
            value={note}
            placeholder="Add a note before approving"
            onChange={(e) => setNote(e.target?.value)}
          />
        </ModalBody>

        <ModalFooter>
          <Button
            // color={'gr'}
            onClick={() => setOpenApproveModal(false)}
            className="ml-4"
          >
            Cancel
          </Button>
          {!approveDeal && (
            <Button
              color={approveDeal ? "primary" : "danger"}
              onClick={handleSendBack()}
            >
              {loading ? (
                <>
                  <Spinner size={"sm"} /> Please Wait
                </>
              ) : (
                " Reject & Send back"
              )}
            </Button>
          )}
          <Button
            color={approveDeal ? "primary" : "danger"}
            onClick={handleApprove(approveDeal)}
          >
            {loading ? (
              <>
                <Spinner size={"sm"} /> Please Wait
              </>
            ) : approveDeal ? (
              "Approve"
            ) : (
              "Reject & Archive"
            )}
          </Button>
          {approveDeal && <Button
            color={"primary"}
            onClick={handleApprove(approveDeal, deal)}
          >
            {loading ? (
              <>
                <Spinner size={"sm"} /> Please Wait
              </>
            ) : (
              "Approve then Convert to Draft Listing"
            )}
          </Button>}
        </ModalFooter>
      </Modal>

      <Modal isOpen={openCancelModal} toggle={(e) => setOpenCancelModal(false)}>
        <ModalHeader>
          {!approveDeal && <Icon name={"alert-fill"} />}
          <span>{modalText}</span>
        </ModalHeader>
        <ModalBody>
          <Input
            type="textarea"
            value={note}
            onChange={(e) => setNote(e.target?.value)}
          />
        </ModalBody>

        <ModalFooter>
          <Button
            // color={'gr'}
            onClick={() => setOpenCancelModal(false)}
            className="ml-4"
          >
            Cancel
          </Button>
          <Button
            color={approveDeal ? "primary" : "danger"}
            onClick={handleCancel(approveDeal)}
          >
            {loading ? (
              <>
                <Spinner size={"sm"} /> Please Wait
              </>
            ) : approveDeal ? (
              "Cancel"
            ) : (
              "Cancel & Archive"
            )}
          </Button>
        </ModalFooter>
      </Modal>

      {isConvertDealModalOpen && <ConvertDealToListingModal deal={deal} approvalNote={note} refreshDealsCallback={refreshDeals} />}
    </>
  );
};
export default DealCard;

const RenderSecondaryAgent = ({ deal }) => {
  const buyerAgent = deal?.buyerAssignedAgent;
  const sellerAgent = deal?.sellerAssignedAgent;

  const buyerClientType = deal?.buyerClientType;
  const sellerClientType = deal?.sellerclientType;

  const externalBuyer = deal?.buyerExternalUser;
  const externalSeller = deal?.sellerExternaluser;

  return (
    <>
      {buyerClientType === DealClientType.Alba ? (
        <p>
          <span className="fw-medium">Buyer Agent:</span>{" "}
          <span>
            {buyerAgent?.user?.first_name ?? ""}{" "}
            {buyerAgent?.user?.last_name ?? ""}
          </span>
        </p>
      ) : buyerClientType === DealClientType.External ? (
        <p>
          <span className="fw-medium">Buyer Agency:</span>{" "}
          <span>
            {externalBuyer?.agency_name ??
              deal?.buyerExternalAgentName ??
              "External Agency"}
          </span>
        </p>
      ) : (
        <p>
          <span className="fw-medium">Buyer Agent:</span> <span>N/A</span>
        </p>
      )}

      {sellerClientType === DealClientType.Alba ? (
        <p>
          <span className="fw-medium">Seller Agent:</span>{" "}
          <span>
            {sellerAgent?.user?.first_name ?? ""}{" "}
            {sellerAgent?.user?.last_name ?? ""}
          </span>
        </p>
      ) : sellerClientType === DealClientType.External ? (
        <p>
          <span className="fw-medium">Seller Agency:</span>{" "}
          <span>
            {externalSeller?.agency_name ??
              deal?.sellerExternalAgentName ??
              "External Agency"}
          </span>
        </p>
      ) : (
        <p>
          <span className="fw-medium">Seller Agent:</span> <span>N/A</span>
        </p>
      )}
    </>
  );
};

const RenderSecondaryClient = ({ deal }) => {
  const buyerInternalUser = deal?.buyerInternalUser;
  const sellerInternalUser = deal?.sellerInternalUser;

  const buyerClientType = deal?.buyerClientType;
  const sellerClientType = deal?.sellerclientType;

  const externalBuyer = deal?.buyerExternalUser;
  const externalSeller = deal?.sellerExternaluser;

  return (
    <>
      {buyerClientType === DealClientType.Alba ? (
        <p>
          <span className="fw-medium">Buyer Client:</span>{" "}
          <span>
            {buyerInternalUser?.first_name ?? ""}{" "}
            {buyerInternalUser?.last_name ?? ""}
          </span>
        </p>
      ) : buyerClientType === DealClientType.External ? (
        <p>
          <span className="fw-medium">Buyer Agency:</span>{" "}
          <span>
            {externalBuyer?.agency_name ??
              deal?.buyerExternalAgentName ??
              "External Agency"}
          </span>
        </p>
      ) : (
        <p>
          <span className="fw-medium">Buyer Client:</span> <span>N/A</span>
        </p>
      )}

      {sellerClientType === DealClientType.Alba ? (
        <p>
          <span className="fw-medium">Seller Client:</span>{" "}
          <span>
            {sellerInternalUser?.first_name ?? ""}{" "}
            {sellerInternalUser?.last_name ?? ""}
          </span>
        </p>
      ) : sellerClientType === DealClientType.External ? (
        <p>
          <span className="fw-medium">Seller Agency:</span>{" "}
          <span>
            {externalSeller?.agency_name ??
              deal?.sellerExternalAgentName ??
              "External Agency"}
          </span>
        </p>
      ) : (
        <p>
          <span className="fw-medium">Seller Client:</span> <span>N/A</span>
        </p>
      )}
    </>
  );
};
